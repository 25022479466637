import React from 'react'; // eslint-disable-line no-unused-vars
import { Link } from 'gatsby-theme-material-ui';

import { Paragraph } from '../components/Texts';
import Layout from '../components/Layout';

const Contact = () => (
  <Layout>
    <Paragraph>
      Votre message a bien été envoyé.
    </Paragraph>
    <Link to="/">Retour à la page d'accueil</Link>
  </Layout>
);

export default Contact;
